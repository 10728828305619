import React, { Component } from "react";
import Carousel from "../../components/Carousel";
import "./Contact.css";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

class Contact extends Component {
  render() {
    return (
      <div>
        <Carousel />
        <div className="intro1">
          <div className="contactWrapper">
            <h1>
              Contact Us
              <div className="line1" />
            </h1>
            <p>Get Everyone Shopping</p>
          </div>
        </div>
        <div className="gridWrapper1">
          <Grid container={true} spacing={8}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <CardMedia
                style={{ height: 100, paddingTop: "71%" }}
                image={require("../../assets/images/contactmap.PNG")}
              />
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Card className="contact">
                <div className="eplTitle">
                  <h1>EPLA Ventures Pte Ltd</h1>
                  <div className="line" />
                  <h3>Address</h3>
                  <p>
                    7 Kaki Bukit Road 1, #04-10 Eunos Technolink, Singapore
                    415937
                  </p>
                  <h3>Tel No. </h3>
                  <p>6846 2566</p>
                  <h3>Email </h3>
                  <p>info@edfworld.com</p>
                  <h3>Office Hours </h3>
                  Monday to Friday 9:30am - 6:30pm Saturday, Sunday and Public
                  Holiday Closed
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default Contact;
