import React, { Component } from "react";
import Carousel from "../../components/Carousel";
import Introduction from "../../components/Introduction";
import Map from "../../components/Map";
import Grid from "@material-ui/core/Grid";
import "./Countries.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import bali from "../../assets/images/bali.jpg";
import mbs from "../../assets/images/mbs.jpg";
import kuta from "../../assets/images/kutabeach.jpg";

interface IUpdates {
  countryData: any;
}

class Countries extends Component {
  render() {
    const images = [
      {
        original: bali,
        thumbnail: bali
      },
      {
        original: mbs,
        thumbnail: mbs
      },
      {
        original: kuta,
        thumbnail: kuta
      }
    ];
    return (
      <div>
        <Carousel />
        <div className="introSG">
          <div className="sgWrapper">
            <h1>
              SINGAPORE
              <div className="line2" />
            </h1>
            <p>Get Everyone Shopping</p>
          </div>
        </div>
        <div className="toBK">
          <p>
            To <br />
            Bangkok <br /> →
          </p>
        </div>
        <div className="gridWrapper3">
          <Grid container={true} spacing={8}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Card className="news">
                <div className="newsTitle">
                  <h1>NEWS</h1>
                  <div className="line3" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Lorem ipsum dolor sit
                    amet.
                  </p>
                </div>
              </Card>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <CardMedia
                style={{ height: 50, paddingTop: "59%" }}
                image={require("../../assets/images/greygoose.jpg")}
              />
            </Grid>
          </Grid>
        </div>
        <div className="galleryTitle">
          <h1>GALLERY</h1>
        </div>
        <div className="imgGallerysg">
          <ImageGallery
            items={images}
            autoPlay={false}
            showThumbnails={true}
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={true}
            thumbnailPosition={"bottom"}
          />
        </div>
      </div>
    );
  }
}
export default Countries;
