import React, { Component } from "react";
import Carousel from "../../components/Carousel";
import Introduction from "../../components/Introduction";
import About from "../../components/About";
import Map from "../../components/Map";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import "./Home.css";
import Updates from "../../components/Updates";
import updatesData from "../../Data/Updates";
import partnersData from "../../Data/Partners";
import Partners from "../../components/Partners";
import Map_marker from "../../components/Map-marker";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Carousel />
        <Introduction />
        <About />
        <div className="maptitle">
          <h1>EDF`S WORLD PRESENCE</h1>
          <h2>Ever Growing, Ever Ready, Ever Present</h2>
        </div>
        <Map />
        <div className="gridWrapper">
          <Grid container={true} spacing={8}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <CardMedia
                style={{ height: 150, paddingTop: "58%" }}
                image={require("../../assets/images/investors.png")}
              />
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Card className="edfRising">
                <div className="risingTitle">
                  <h1>STRATEGY VALUES</h1>
                  <div className="line" />
                  <p>
                    Taking the initiative to be in the forefront position to
                    venture and tap the undeveloped markets, is key to EDF World
                    strategy values.
                  </p>
                  <p>
                    Across towns located at carefully selected borders and
                    waterway around the world, EDF World outlets are set up to
                    provide travellers a unique travel retail experience with
                    its exclusive and well-known international products
                  </p>
                  <p>
                    EDF World unique business decisions were made after a keen
                    observation of the travelling trends and the consumer
                    spending traits. Bringing a certain increase exposures to
                    the brands EDF World represented for our partners.
                  </p>
                  <p>
                    Positioned readily, EDF World put a strong priority to first
                    capture these values.
                  </p>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className="updatesTitle">
          <h1>EDF UPDATES</h1>
        </div>
        <div className="updatesWrapper">
          <Grid container={true} spacing={8}>
            {updatesData.map(dataObj => {
              return (
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Updates contentData={dataObj} />
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div className="partnersTitle">
          <h1>EDF PARTNERS</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
        <div className="partnersWrapper">
          <Grid container={true} spacing={8}>
            {partnersData.map(dataObj => {
              return (
                <Grid item lg={3} md={3} sm={4} xs={4}>
                  <Partners partnerData={dataObj} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
export default Home;
