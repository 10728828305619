export default [
  {
    image: "greygoose.jpg",
    title: "Best Price",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magnaaliqua."
  },
  {
    image: "shelves.jpg",
    title: "Widest Selection",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magnaaliqua."
  },
  {
    image: "singapore.jpg",
    title: "Discover Now",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magnaaliqua."
  }
];
