import { AppBar, SwipeableDrawer, Toolbar } from "@material-ui/core/";
import * as React from "react";

import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "./Navigation.css";

// Images
import headerBackgroundImagePath from "../../assets/images/edfworld.png";
import headerMobileBackgroundImagePath from "../../assets/images/edfworld.png";

class Navigation extends React.Component {
  public state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    top: false
  };

  public toggleDrawer = (open: boolean) => () => {
    this.setState({
      top: open
    });
  };

  public render() {
    const { top } = this.state;

    const renderMobileMenu = (
      <SwipeableDrawer
        anchor="top"
        open={top}
        onClose={this.toggleDrawer(false)}
        onOpen={this.toggleDrawer(false)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={this.toggleDrawer(false)}
          onKeyDown={this.toggleDrawer(false)}
          className="divDrawer"
        >
          <a href="#" key="about" className="mobileNav">
            <div>About Us</div>
          </a>
          <a href="#" key="products" className="mobileNav">
            <div>Products</div>
          </a>
          <a href="#" key="location" className="mobileNav">
            <div>Locations</div>
          </a>
          <a href="#" key="partners" className="mobileNav">
            <div>Partners</div>
          </a>

          <Link to="/Contact/" className="mobileNav">
            <div key="contact">Contact</div>
          </Link>
        </div>
      </SwipeableDrawer>
    );

    return (
      <div className="navRoot">
        <AppBar position="static" color="inherit" className="navAppBar">
          <Toolbar className="navTitle" disableGutters={true}>
            <div className="navDesktop">
              <Link to={"/Home"}>
                <img src={headerBackgroundImagePath} width="100" />
              </Link>
            </div>

            <div className="navMobile">
              <Link to={"/Home"}>
                <img src={headerMobileBackgroundImagePath} width="100" />
              </Link>
            </div>

            <div className="navGrow" />

            <div className="navDesktop">
              <a href="#" key="about" className="desktopNav">
                <div>About Us</div>
              </a>
              <a href="#" key="products" className="desktopNav">
                <div>Products</div>
              </a>
              <div className="desktopDropdown">
                <button className="dropBtn">
                  <div>Locations</div>
                </button>
                <div className="dropdownContent">
                  <a href="#" key="news" className="dropItem">
                    <div>News</div>
                  </a>
                  <a href="#" key="promotions" className="dropItem">
                    <div>Promotions</div>
                  </a>
                </div>
              </div>
              <a href="#" key="partners" className="desktopNav">
                <div>Partners</div>
              </a>

              <Link to="/Contact/" className="desktopNav">
                <div key="contact">Contact</div>
              </Link>
            </div>

            <div className="navMobile">
              <IconButton onClick={this.toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

export default Navigation;
